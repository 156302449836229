import React, { Component } from "react";
import {Link } from "react-router-dom";
import logo from "../../images/kmuttLogo.png";

class Header extends Component {
  render() {
    return (
      <nav className="bg-white flex justify-between static items-center h-[69px] md:h-[90px] xl:h-[65px]">
        <div>
          <img
          className="ml-[20px] mr-auto justify-self-start cursor-pointer h-[50.75px] md:h-[80.75px] xl:h-[45px] "
          src={logo}
          />
        </div>

        <h className="hidden xl:contents text-[#FF8383] font-bold  xl:text-[3vh] ">
        สิ้นสุดระยะเวลาการลงคะแนนเสียงแล้ว
        </h>
        <div className="flex items-center">
          <ul>
          <li className="text-[#3c8aff] font-bold mr-[20px] md:text-[26px] xl:text-[14px]">
            <Link to="/home">Home</Link>
          </li>
        </ul>
        <div
          className="bg-[#3c8aff] p-[10px] text-center rounded-3xl w-[6rem] md:w-[10rem] md:h-[60px] xl:h-[38px] xl:w-[7.5rem]
                          text-[16px] md:text-[26px] xl:text-[14px] text-white mr-5 mt-0 hover:bg-[#FFCD4E] hover:border-[#ffe6a6] "
        >
          <Link to="/connect-Wallet">เลือกตั้ง</Link>
        </div>
        </div>
        
        
      </nav>
    );
  }
}

export default Header;