import React, { useState, useEffect } from "react";
import logo from "../../images/kmuttLogo.png";
import Cookies from "js-cookie";

function HeaderLogin() {
  const [defaultAccount, setDefaultAccount] = useState(" ");

  useEffect(() => {
    const accToken = Cookies.get("acc_token");
    setDefaultAccount(accToken);

    return () => {};
  }, [Cookies]);

  const onClickToVote = () => {
    Cookies.remove("acc_token", defaultAccount);

    window.location.href = "/home";
  };

  return (
    <nav className="bg-white flex justify-between static items-center h-[69px] md:h-[90px] xl:h-[65px]">
      <img
        className="ml-[20px]  cursor-pointer h-[50.75px] md:h-[80.75px] xl:h-[55px] "
        src={logo}
      />

      <h className="hidden xl:contents text-[#6b91fc] font-bold  xl:text-[2.2vh] ">
        Your ethereum address: {defaultAccount}
      </h>

      <div
        className="bg-[#FF8383] p-[10px] text-center rounded-3xl w-[8rem] md:w-[14rem] md:h-[60px] xl:h-[38px] xl:w-[7.5rem]
                          text-[16px] md:text-[26px] xl:text-[16px] text-white mr-5 mt-0 hover:bg-[#FFCD4E] hover:border-[#ffe6a6] "
      >
        <button onClick={onClickToVote}>ออกจากระบบ</button>
      </div>
    </nav>
  );
}

/*         

<li>
    <link to="/home">Home</link>
</li>
<li>
    <link to="/login">การเลือกตั้ง</link>
</li>
          
          */

export default HeaderLogin;