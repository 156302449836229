import React, { useState } from "react";
import { ethers } from "ethers";
import Cookies from "js-cookie";
import "./Login.css";
import imgInLogin from "../../images/login1.png";
import imgMetaMask from "../../images/MetaMask.png";
import Header from "../fragments/Header";
import Swal from "sweetalert2";

const WalletCard = () => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [defaultAccount, setDefaultAccount] = useState(null);
  const [userBalance, setUserBalance] = useState(null);
  const [connButtonText, setConnButtonText] = useState("Connect Wallet");

  //click button เพื่อเชื่อมต่อ
  const connectWalletHandler = () => {
    if (window.ethereum && window.ethereum.isMetaMask) {
      console.log("MetaMask Here!");

      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((result) => {
          accountChangedHandler(result[0]);
          setConnButtonText("Wallet Connected");
          getAccountBalance(result[0]);
        })
        .catch((error) => {
          setErrorMessage(error.message);
        });
    } else {
      console.log("Need to install MetaMask");
      setErrorMessage("Please install MetaMask browser extension to interact");
    }
  };

  // update account, will cause component re-render
  const accountChangedHandler = (newAccount) => {
    setDefaultAccount(newAccount);
    getAccountBalance(newAccount.toString());
  };

  const getAccountBalance = (account) => {
    window.ethereum
      .request({ method: "eth_getBalance", params: [account, "latest"] })
      .then((balance) => {
        setUserBalance(ethers.utils.formatEther(balance));
        setErrorMessage("MetaMask is connecting"); // โชว์ให้ user เห็นถึงว่ามีการอัปเดรต
      })
      .catch((error) => {
        setErrorMessage(error.message);
      });
  };

  const chainChangedHandler = () => {
    // reload the page to avoid any errors with chain change mid use of application
    window.location.reload();
  };

  // listen for account changes
  window.ethereum.on("accountsChanged", accountChangedHandler);

  window.ethereum.on("chainChanged", chainChangedHandler);

  const onClickToVote = () => {
    if (!defaultAccount) {
      Swal.fire({
        title: "Please, connect Metamask",
        text: "โปรดทำการ connect wallet กับ Metamask Wallet ก่อนทำการเริ่มลงคะแนน!",
        icon: "warning",
        confirmButtonText: "ตกลง",
      });
      return 
    } else {
      Cookies.set("acc_token", defaultAccount);
      window.location.href = "/vote-council";
    }
  };

  // const onClickToVote = () => {
  //   if (!defaultAccount) {
  //     Swal.fire({
  //       title: "Please, connect Metamask",
  //       text: "โปรดทำการ connet wallet กับ Metamask Wallet ก่อนทำการเริ่มลงคะแนน!",
  //       icon: "warning",
  //       confirmButtonText: "Ok",
  //     });

  //   }return Cookies.set("acc_token", defaultAccount);
  //     window.location.href = "/vote-council"
  // };

  // ไม่ใช้แล้ว
  // const handleLogin = (e) => {
  //   e.preventDefault(); //ไม่ให้หน้า web refash
  //   const { defaultAccount } = e.target.elements;
  //   try {
  //     //จะเรียกใช้ web3 js
  //   } catch (error) {
  //     alert(error);
  //   }

  //   window.location.href = "/vote-council";
  // };

  return (
    <div>
      <Header />

      <div className="body-login">
        <div className="container">
          <div className="cardImg">
            <img className="imgLogin1" src={imgInLogin} alt="picture voting"/>
          </div>
          <div className="flex bg-white basis-[90%] xl:basis-[70%] h-[100%] xl:h-[610px] xl:ml-[5%] justify-center items-center rounded-[50px]">
            <div className="grid ccontent-center justify-items-center w-full md:h-[70%] xl:h-[85%] rounded-[25px] ">
              <div className="text-center">
                <div className="grid justify-self-center">
                  <img
                    className="justify-self-center md:h-32 xl:h-24"
                    src={imgMetaMask}
                    alt="logo-metamask"
                  />
                </div>
                <div className="mt-5 md:mt-12 lg:mt-4 xl:mt-4">
                  <h className="text-[1.8rem] md:text-[2.8rem] xl:text-[1.8rem] text-center font-bold md:mt-12 xl:mt-8">
                    Please connect only{" "}
                  </h>
                  <h className="text-[1.8rem] md:text-[2.8rem] xl:text-[1.8rem] text-center text-[#E25100] font-bold">
                    Metamask Wallet
                  </h>
                  <br />
                  <h className="text-[#A1A1A1] text-[1.9vh] xl:text-[2vh] md:text-[2.1vh] mt-3">
                    *โปรดเชื่อมต่อกับบัญชี wallet
                    ที่ได้ทำการลงทะเบียนไว้เท่านั้น
                  </h>
                  <br />
                  <br />
                </div>
              </div>
              <div className="flex justify-center items-center">
                <button
                  onClick={connectWalletHandler}
                  className="bg-[#F6851B] border-2 border-[#F6851B] rounded-[30px] p-[10px] text-center w-[180px] md:w-[280px] xl:w-[180px] md:h-[75px] xl:h-[45px]
                                      text-[17px] md:text-[28px] xl:text-[15px] text-white hover:bg-[#FFCD4E] hover:border-2 hover:border-[#ffe6a6]"
                  type="submit"
                >
                  Connect wallet
                </button>
              </div>
              <div className="grid content-end justify-items-start w-[80%] ">
                <br />
                <h className="text-[1.8vh] xl:text-[2vh] md:text-[1.8vh] text-[#A1A1A1] font-semibold">
                  ข้อมูล Metamask Wallet ของคุณ
                </h>
                <div className="flex  w-[80%]">
                  <h className="text-[1.8vh] xl:text-[2vh] md:text-[1.8vh] text-[#A1A1A1]">
                    Address:
                  </h>
                  <input
                    className="w-[100%] bg-transparent focus:outline-none text-[1.8vh] xl:text-[2vh] md:text-[1.8vh] text-[#A1A1A1] ml-2"
                    value={defaultAccount}
                  />
                </div>

                <h className="text-[1.8vh] xl:text-[2vh] md:text-[1.8vh] text-[#A1A1A1]">
                  Balance: {userBalance}
                </h>
                <h className="text-[1.8vh] xl:text-[2vh] md:text-[1.8vh] text-[#A1A1A1]">
                  status: {errorMessage}
                </h>
                <br />
                <br />
              </div>
              <div className="grid content-center justify-items-center w-[80%] border-t-4 border-[#DFDFDF]">
                <button
                  className="bg-[#6b91fc] border-2 border-[#7bb0ff] mt-4 md:mt-12 xl:mt-8 rounded-[30px] p-[10px] text-center w-[180px] md:w-[280px] xl:w-[180px] md:h-[75px] xl:h-[45px]
                                      text-[17px] md:text-[28px] xl:text-[15px] text-white hover:bg-[#FFCD4E] hover:border-2 hover:border-[#ffe6a6] focus:outline-none focus:ring focus:ring-[#ffe6a6]"
                  type="submit"
                  onClick={onClickToVote}
                >
                  เริ่มลงคะแนน
                </button>
                <h className="text-[#A1A1A1] text-[1.9vh] xl:text-[2vh] md:text-[2.1vh] mt-3">
                  ทำการ connect wallet ก่อนทำการลงคะแนน
                </h>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WalletCard;
