import React, { useEffect, useState } from "react";
import Header from "../fragments/Header";
import { winnername } from "./function";

function Result() {
  //const [sendPromise, setSendPromise] = useState(null);
  const [winnerNameKmutt, setWinnerNameKmutt] = useState("");

  //ไม่ใช่เช็ค defaultAccount check time
  /*  const { defaultAccount } = useState(WalletCard);
  if (!defaultAccount) {
    //return <Redirect to="/connect-Wallet" />;
  }
 */
  const onClickTextFacebook = () => {
    window.location.href =
      "https://www.facebook.com/KMUTT-Election-111526570306064/?hc_ref=ARQdkr-drZJoqAtX3N3ZbDr3beZ9UFtgfL_a8R_SmbXZWTngTgaKR_G67sRDvRQl4FM&fref=nf&__xts__[0]=68.ARA4YTI8ksBXkx3zAImdaXLZkYqYwmgS5IbSFbjHZE6hh48D8YJjek8om7lIQbFugc48jVsl4UlcF4i3wp6X8j7crzU0_4xA48Uy16fEqkwxLQwfzZKE5N-NnlTqVFtOzBX0pUT2nx2Y89M5urjqZ6R0ENi0hlqBTSZwe9za9TytUNcilHHSI0Al5U0B6Fh_qRyj6QXpeXOBbPlz2rCM7sMgAhprkjVV1FRSFBLUxkwvXr6qE6n1hKBzfr-bRRyb3xX2NMuWTeIRAXd3zLw2NBPC5JysxHce68GgcaFefTUSqsoXnIE%22;" 
  };

  const onClickToValidateContract = () => {
    window.location.href =
      "https://kovan.etherscan.io/address/0x1a0911c32C82771692971327EACb1Dafc5E53138" 
  };

  /*   const winnerName = async () => {
    let x = await winnername();
    console.log(x);
    setSendPromise(x);
    //return sendPromise;

  }; */

  useEffect(async () => {
    let x = await winnername();
    console.log("aaaaaa");
    console.log(x);
    setWinnerNameKmutt(x);
  }, []);

  return (
    <div>
      <Header />
      <div className="body-voteCouncil">
        <div className="grid justify-items-center align-middle h-full xl:w-full p-5">
          <div className="bg-white w-full h-[100%] md:h-[110%] xl:w-[80%] p-[3%] rounded-[45px] mt-4 md:mt-10">
            <div className="grid h-[90%] md:h-[95%] xl:h-full w-full">
              <div className="w-full text-center mt-8 md:mt-8 xl:mt-0 p-2">
                <h className="text-[#898888] text-[3.9vh] md:text-[3.9vh] xl:text-[6.4vh] font-bold">
                  ผลคะแนนการเลือกตั้ง <br />{" "}
                  คณะกรรมการองค์การบริหารองค์การนักศึกษา
                </h>
                <br />
                <h className="text-[#898888] text-[2vh] md:text-[2.4vh]  xl:text-[2.5vh]">* โปรดทำการเชื่อมต่อกับ Metamask Wallet เพื่อดูผลพรรคที่ได้รับการเลือกตั้ง</h>
              </div>
              <div className="md:mt-1 xl:mt-4">
                <div className="flex mt-8 md:mt-3 md:leading-loose xl:leading-8">
                  <div className="w-full text-center">
                    <h className="text-[#19a046] text-[3.2vh] md:text-[3vh] xl:text-[3.8vh] mt-5">
                      พรรคที่ได้รับการเลือกตั้ง : {winnerNameKmutt}
                    </h>
                  </div>
                </div>
              </div>
              <div className="flex justify-center items-end ">
                <div>
                  <h className="text-[#898888] text-[2vh] md:text-[2.4vh]  xl:text-[2.5vh]">
                   Click to validate :{" "}
                  </h>
                  <button
                    type="button"
                    className="btn btn-info"
                    onClick={onClickToValidateContract}
                  >
                    <h className="underline text-[#898888] text-[2vh] md:text-[2.4vh]  xl:text-[2.5vh] font-semibold">
                      “Contract Address”
                    </h>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center items-center h-[80%] mt-4 md:mt-12 xl:mt-20">
            <div>
              <h className="text-[#2C2C2C] text-[2.3vh] md:text-[2.4vh]  xl:text-[2.5vh]">
                และสามารถติดตามข่าวสารการเลือกตั้งได้ที่{" "}
              </h>
              <button
                type="button"
                className="btn btn-info"
                onClick={onClickTextFacebook}
              >
                <h className="underline text-[#2C2C2C] text-[2vh] md:text-[2.4vh]  xl:text-[2.5vh] font-semibold">
                  KMUTT ELECTION
                </h>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Result;