import React, { Component, useState , useEffect } from "react";
import finishVote from "../../images/finishVote.png";
import HeaderLogin from "../fragments/HeaderLogin";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import { getTransactions } from "./function";

function VoteAlready() {
  const [defaultAccount, setDefaultAccount] = useState("");
  const [transactionHash, setTransactionHash] = useState("");

  useEffect(() => {
    const accToken = Cookies.get("acc_token");
    setDefaultAccount(accToken);
    if (!accToken) {
      Swal.fire({
        title: "Error!",
        text: "โปรดทำการเชื่อมต่อกับ Metamask Wallet ก่อน",
        icon: "error",
        confirmButtonText: "ไปหน้าเชื่อมต่อ",
      })
      .then((result) => {
        if (result.isConfirmed) {
          window.location.href = "/connect-Wallet";
        } 
      });
    }

    return () => {};
  }, [Cookies]);

  useEffect( ()=> {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const transaction_hash = params.get('transaction_hash');
    console.log("voteAlready: ", transaction_hash)
    setTransactionHash(transaction_hash)
  }, [])

  const onClickTextFacebbok = () => {
    window.location.href =
      "https://www.facebook.com/KMUTT-Election-111526570306064/?hc_ref=ARQdkr-drZJoqAtX3N3ZbDr3beZ9UFtgfL_a8R_SmbXZWTngTgaKR_G67sRDvRQl4FM&fref=nf&__xts__[0]=68.ARA4YTI8ksBXkx3zAImdaXLZkYqYwmgS5IbSFbjHZE6hh48D8YJjek8om7lIQbFugc48jVsl4UlcF4i3wp6X8j7crzU0_4xA48Uy16fEqkwxLQwfzZKE5N-NnlTqVFtOzBX0pUT2nx2Y89M5urjqZ6R0ENi0hlqBTSZwe9za9TytUNcilHHSI0Al5U0B6Fh_qRyj6QXpeXOBbPlz2rCM7sMgAhprkjVV1FRSFBLUxkwvXr6qE6n1hKBzfr-bRRyb3xX2NMuWTeIRAXd3zLw2NBPC5JysxHce68GgcaFefTUSqsoXnIE";
  };

  const onClickKovanLink = () => {

    window.location.href = "https://kovan.etherscan.io/tx/" + transactionHash
  }

  return (
    <div>
      <HeaderLogin />
      <div className="body-voteCouncil">
        <div className="grid justify-items-center align-middle h-full md:w-full xl:w-full p-5">
          <div className="bg-white w-full h-[100%] md:h-[98%] md:w-[100%] xl:w-[80%] p-[3%] rounded-[45px] mt-2 md:mt-10 xl:mt-2 ">
            <div className="grid justify-items-center align-middle h-full w-full text-center">
              <div className="p-16 md:p-20 xl:p-5">
                <img
                  className="h-[150px] md:h-[250px] xl:h-[200px]"
                  src={finishVote}
                />
              </div>
              <div className="w-full">
              <h className="text-[#898888] text-[2.75vh] md:text-[4.5vh] xl:text-[5.5vh] font-bold xl:mt-2">
                นักศึกษาได้ทำการเลือกตั้งสำเร็จแล้ว
              </h>
              <br />
              <br />
              <h1 className="text-[#898888] text-[1.8vh] md:text-[2.6vh] xl:text-[2.5vh] ">
                ขอบคุณที่ร่วมมาใช้สิทธิ์ของท่าน และขอบคุณที่เป็นส่วนหนึ่ง
                ในการเปลี่ยนแปลงมหาวิทยาลัยของพวกเรา
              </h1>
              </div>
        
              <div className="mt-5 w-full">
              <button
                type="button"
                className=""
                onClick={onClickKovanLink}
              >
                <h className="text-[#FF2626] text-[1.8vh] md:text-[2.6vh] xl:text-[2.5vh]">
                  Validate : Click!
                  <input
                    className="w-[100%] bg-transparent focus:outline-none text-[1.8vh] md:text-[2.6vh] xl:text-[2.5vh] text-[#FF2626] "
                    value={transactionHash}
                  />
                </h>
                
                </button>
              </div>
            </div>
          </div>
          <div className="flex justify-center items-center h-[80%] mt-4 md:mt-12 xl:mt-5">
            <div>
              <h className="text-[#2C2C2C] text-[1.8vh] md:text-[2.4vh]  xl:text-[2.5vh]">
                และสามารถติดตามข่าวสารการเลือกตั้งได้ที่ {" "}
              </h>
              <button
                type="button"
                className="btn btn-info"
                onClick={onClickTextFacebbok}
              >
                <h className="underline text-[#2C2C2C] text-[2vh] md:text-[2.4vh]  xl:text-[2.5vh] font-semibold">
                  KMUTT ELECTION
                </h>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VoteAlready;
