import React from "react";
import Button from "@mui/material/Button";
import Home from "./components/pages/Home";
import VoteCouncil from "./components/pages/voteCouncil";
import VoteAlready from "./components/pages/VoteAlready";
import Result from "./components/pages/Result";
import WalletCard from "./components/pages/test_metamask";
// import testconnect from "./components/pages/testconnect";
// import testvote from "./components/pages/testvote";
// import testcontract from "./components/pages/testcontract";

import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";

export default function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={() => <Redirect to="/home" />} />
        <Route exact path="/home" component={Home} />
        <Route exact path="/connect-Wallet" component={WalletCard} />
        <Route path="/vote-council" component={VoteCouncil} />
        <Route path="/vote-already" component={VoteAlready} />
        <Route path="/election-result" component={Result} />
        {/* <Route path="/testconnect" component={testconnect}/>
        <Route path="/testvote" component={testvote}/>
        <Route path="/testcontract" component={testcontract}/> */}
      </Switch>
    </Router>
  );
}