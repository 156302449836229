import React, { Component } from "react";
import "./Home.css";
import imgResult from "../../images/result.png";
import imgPeople from "../../images/home2.png";
import {Link } from "react-router-dom";
import Header from "../fragments/Header";

class Home extends Component {
  render() {
    return (
      <div>
        <Header />
        <div className="home-body">
          <div className="flex justify-center xl:items-center h-full">
            <div className="grid xl:flex xl:justify-center xl:align-middle bg-white w-[85%] md:w-[95%] xl:w-[85%] h-[120%] md:h-[120%] lg:h-[120%] xl:h-[92%] rounded-[45px] mt-4 md:mt-8 xl:mt-0 ">
              <div className="text-center xl:w-[80%] h-[90%] md:h-[110%] xl:mt-1 xl:h-[100%] basis-[90%] xl:basis-[65%] ">
              <h className="xl:hidden text-[#FF8383] font-bold text-[2.4vh] md:text-[3vh] xl:text-[3vh]">
                  สิ้นสุดระยะเวลาการลงคะแนนเสียงแล้ว
             </h>
                <h className="text-[#6b91fc] text-[4vh] md:text-[5vh] xl:text-[6vh] md:leading-[80px] xl:leading-[50px] font-bold xl:font-semibold">
                  <br /> - KMUTT Election - <br />
                  Blockchain Base
                </h>
                <div className="flex justify-center align-middle h-[68%] md:ml-5">
                  <div className="hidden md:flex md:basis-[25%] xl:basis-[30%] self-end text-center md:mb-12 xl:mb-0">
                    <img className="xl:w-[90%]" src={imgPeople} />
                  </div>
                  <div className="flex justify-center align-middle md:basis-[70%] p-8 md:p-5">
                    <div className="grid content-center md:ml-5 mt-8 md:mt-0 xl:p-2 xl:mr-6">
                      <p className="font-[#898888] text-[2.2vh] md:text-[2.2vh] xl:text-[2.5vh]">
                        Web Application สำหรับการเลือกตั้งองค์การนักศึกษาของมหาวิทยาลัย โดยนำเอาเทคโนโลยี
                        blockchain เข้ามาใช้กับการเลือกตั้ง
                        ทำให้ระบบมีความปลอดภัย ยากต่อการโจมตีหรือแก้ไขข้อมูล
                      </p>
                      <br />
                      <p className="font-[#898888] text-[2.2vh] md:text-[2.2vh] xl:text-[2.5vh]">
                        ผู้มีสิทธิ์เลือกตั้งสามารถลงคะแนนได้ผ่านทุกที่ที่มีอินเทอร์เน็ต
                        ซึ่งเป็นการลดปัญหาในเรื่องของการที่ผู้มีสิทธิ์เลือกตั้งที่อยู่
                        ห่างไกล ไม่สามารถเดินทางมาเลือกตั้งได้ และผู้ลงคะแนน
                        สามารถตรวจสอบผลผู้ชนะการเลือกตั้งได้ทันที หลังจากจบ
                        การเลือกตั้ง
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
              <div className="flex justify-center md:items-end xl:items-center xl:basis-[45%] h-full xl:h-full ">
                <div className="flex justify-center xl:items-center md:h-[100%] xl:h-[80%] xl:w-[100%] xl:border-l-4 xl:border-[#DFDFDF]">
                  <Link to="/election-result">
                    <div className="flex justify-center xl:items-center ">
                      <img
                        className="w-[70%] h-[70%] md:w-[98%] md:h-[98%] xl:w-[75%] xl:h-[85%] "
                        src={imgResult}
                      />
                    </div>
                  </Link>
                </div>
              </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;