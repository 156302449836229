import React, { useEffect, useState } from "react";
import "./voteCouncil.css";
import council1 from "../../images/council1.png";
import council2 from "../../images/council2.png";
import HeaderLogin from "../fragments/HeaderLogin";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import { vote } from "./function";

function VoteCouncil() {
  const [defaultAccount, setDefaultAccount] = useState("");
  useEffect(() => {
    const accToken = Cookies.get("acc_token");
    setDefaultAccount(accToken);
    if (!accToken) {
      Swal.fire({
        title: "Error!",
        text: "โปรดทำการเชื่อมต่อกับ Metamask Wallet ก่อน",
        icon: "error",
        confirmButtonText: "ไปหน้าเชื่อมต่อ",
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href = "/connect-Wallet";
        }
      });
    }

    return () => {};
  }, [Cookies]);

  const voteHandler = (value) => () => {
    console.log(value);
     vote(value);
  };


  return (
    <div>
      <HeaderLogin />
      <div className="body-voteCouncil">
        <div className="flex justify-center align-middle h-full xl:w-full p-5 md: lg:p-1 xl:p-5">
          <div className="bg-white w-[100%] h-[93em] md:h-[98%] md:mt-5 lg:h-[96%] lg:w-[60rem]  xl:h-[98.8%] xl:mt-1  xl:w-[80%] p-[3%] xl:p-[2%] rounded-[45px] ">
            <div className="h-full w-full text-center">
              <h className="contents xl:hidden text-[#6b91fc] font-bold text-[1.6vh] md:text-[2.2vh]">
                Your ethereum address: {defaultAccount}
              </h>{" "}
              <br />
              <br />
              <h className="text-[3vh] md:text-[4vh]  xl:text-[5.5vh]  font-bold">
                เลือกคณะกรรมการองค์การบริหารองค์การนักศึกษา
              </h>{" "}
              <h1 className="text-[#da6060] text-[2vh] md:text-[2.2vh] lg:text-[20px] xl:text-[2.4vh] mt-3">
                *โปรดเลือกพรรคเพียง 1 พรรคเท่านั้น กดที่ปุ่ม "เลือก"
                ข้างล่างพรรคที่ต้องการเลือก เพื่อทำการโหวต
              </h1>
              <h1 className="text-[#da6060] text-[2vh] md:text-[2.2vh] lg:text-[20px] xl:text-[2.4vh] mt-1">
              เมื่อทำการโหวตเสร็จสิ้น กรุณารอสักครู่ ระบบจะทำการส่งไปยังหน้าถัดไป เพื่อให้ผู้ใช้งานตรวจสอบธุรกรรมที่เกิดขึ้น
              </h1>
              <div className="auto-grid align-middle justify-items-center w-full mt-8 md:mt-16 lg:mt-8 xl:mt-16 ">
                <div className="grid justify-items-center w-64 ">
                  <div className="grid justify-items-center bg-[#C4C4C4] h-56 w-[80%]">
                    <div className=" h-40">
                      <img className="p-2 h-40" src={council1} alt="group1" />
                    </div>
                    <h className="p-2 text-[16px] md:text-[22px] lg:text-[20px] xl:text-[16px] ">
                      1. พรรคพะลังประชาฬัด
                    </h>
                  </div>
                  <div className="p-4">
                    <button
                      type="button"
                      className="bg-[#50E87A] rounded-3xl p-[8px] text-center w-[90px] md:w-[100px]
                      text-[16px] md:text-[24px] lg:text-[20px] xl:text-[16px] text-white hover:bg-[#FFCD4E]"
                      onClick={voteHandler("1")}
                      // value='1'
                    >
                      เลือก
                    </button>
                  </div>
                </div>
                <div className="grid justify-items-center w-64 ">
                  <div className="grid justify-items-center bg-[#C4C4C4] h-56 w-[80%]">
                    <div className=" h-40">
                      <img className="p-2 h-40" src={council2} alt="group2" />
                    </div>
                    <h className="p-2 text-[16px] md:text-[22px] lg:text-[20px] xl:text-[16px] ">
                      2. พรรคเพื่อมอ
                    </h>
                  </div>
                  <div className="p-4">
                    <button
                      className="bg-[#50E87A] rounded-3xl p-[8px] text-center w-[90px] md:w-[100px]
                    text-[16px] md:text-[24px] lg:text-[20px] xl:text-[16px] text-white hover:bg-[#FFCD4E]"
                      onClick={voteHandler("2")}
                    >
                      เลือก
                    </button>
                  </div>
                </div>
                <div className="grid justify-items-center w-64 ">
                  <div className="grid justify-items-center bg-[#C4C4C4] h-56 w-[80%]">
                    <div className=" h-40">
                      <img className="p-2 h-40" src={council2} alt="group3" />
                    </div>
                    <h className="p-2 text-[16px] md:text-[22px] lg:text-[20px] xl:text-[16px] ">
                      3. พรรคมดส้ม
                    </h>
                  </div>
                  <div className="p-4">
                    <button
                      className="bg-[#50E87A] rounded-3xl p-[8px] text-center w-[90px] md:w-[100px]
                    text-[16px] md:text-[24px] lg:text-[20px] xl:text-[16px] text-white hover:bg-[#FFCD4E]"
                      onClick={voteHandler("3")}
                    >
                      เลือก
                    </button>
                  </div>
                </div>
                <div className="grid justify-items-center w-64 ">
                  <div className="flex items-center justify-center bg-[#C4C4C4] h-56 w-[80%]">
                    <h className="text-[16px] md:text-[22px] xl:text-[16px]">
                      ไม่ประสงค์ลงคะแนน
                    </h>
                  </div>
                  <div className="p-4">
                    <button
                      className="bg-[#50E87A] rounded-3xl p-[8px] text-center w-[90px] md:w-[100px]
                    text-[16px] md:text-[24px] lg:text-[20px] xl:text-[16px] text-white hover:bg-[#FFCD4E]"
                      onClick={voteHandler("0")}
                    >
                      เลือก
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VoteCouncil;


  //  const voteHandler = () => {
  //    index_value = value
  // //   vote(index_value);
  // //   // window.location.href = "/vote-already";
  // // }

  // const [value, setValue] = useState("");
  //  const [index_value, setIndex_value] = useState("");
  
  // async function voteHandler(value) {
  //   await console.log(value);
  //   //await vote(value);
  //   //await window.location.href = "/vote-already";
  //  }

  // const voteHandler = (value) => () => {
  //   console.log(value);
  //   this.vote(value);
  //   this.window.location.href = "/vote-already";
  // };