import ContractABI from "../../ContractABI.json";

import { ethers } from "ethers";
const contractAddress = "0x1a0911c32C82771692971327EACb1Dafc5E53138";

export async function vote(value) {
  console.log(value);
  const tempProvider = new ethers.providers.Web3Provider(window.ethereum);


  console.log(tempProvider);

  const tempSigner = tempProvider.getSigner();

  console.log(tempSigner);

  let tempContract = new ethers.Contract(
    contractAddress,
    ContractABI,
    tempSigner
  );
 

  const transaction =  await tempContract.vote(value);


  const receipt = await transaction.wait();
  const transactionHash = receipt.transactionHash;

  console.log(transactionHash);
 

  
  return (window.location.href = "/vote-already?transaction_hash=" + transactionHash);
}

export async function winnername() {
  let tempProvider = new ethers.providers.Web3Provider(window.ethereum);
  console.log("eiei");

  let tempSigner = tempProvider.getSigner();

  console.log(tempSigner);

  let tempContract = new ethers.Contract(
    contractAddress,
    ContractABI,
    tempSigner
  );

  var sendPromise = await tempContract.winnerName();
  console.log("hello");
  console.log(sendPromise);
  return sendPromise;
}